import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation, I18nextProvider } from 'react-i18next'
import Cookies from 'js-cookie'

import { LogoutRounded, AccountCircle, } from '@mui/icons-material'
import DashboardIcon from '@mui/icons-material/Dashboard';
import { AppBar, Toolbar, Typography, IconButton, Paper, Avatar, Box, Stepper, Step, StepLabel, CssBaseline, LinearProgress, Container, ThemeProvider, createTheme, } from '@mui/material'

import { setUser, resetAuth } from '../slices/AuthSlice'
import { setPage, setBanks, setLanguage } from '../slices/AppSlice'
import { setTransaction, resetTransaction } from '../slices/TransactionSlice'

import { setBank, setEmail, setName, setSurname, setNational, setToken, setOrder, setRefererURL, setCallbackURL, setPrice, setCurrency, setError, resetData } from '../slices/DataSlice'
import { useGetBankListMutation, useCreateTransactionMutation } from '../app/API'

import { IconStepConnector } from '../components/StyledComponents'
import LanguageSwitcher from '../components/LanguageSwitcher'
import CustomStepIcons from '../components/CustomStepIcons'
import StepIcons from '../components/StepIcons'

import MoneyForm from './MoneyForm'
import BankForm from './BankForm'
import TransactionForm from './TransactionForm'

import i18n from '../app/Localisation'
import settings from 'settings'

export const theme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
          WebkitFontSmoothing: 'antialiased',
          MozOsxFontSmoothing: 'grayscale',
        },
        header: {
          backgroundColor: 'transparent !important',
          boxShadow: 'none !important',
        },
        '.rccs': {
          width: '100% !important',
        },
        '.rccs__card': {
          width: '100% !important',
        },
      }
    }
  }
})

let isDefaultTheme = false;
const themeProperties = {
  stepIcons: CustomStepIcons(),
};
function App() {
  React.useEffect(() => {
    const systemDomain = settings.base;

    import('./Base.scss');
    switch (systemDomain) {
      case 'moonduck.live':
        import('../themes/MoonDuck.scss');
        break;
      case 'kokospay.com':
        import('../themes/Cocos.scss');
        break;
      case 'paydaddy.live':
        import('../themes/Daddy.scss');
        break;
      case 'makeitpay.app':
        import('../themes/Makeit.scss');
        break;
      case '1942pay.com':
        import('../themes/1942.scss');
        break;
      case 'claritypay.org':
        themeProperties.stepIcons = CustomStepIcons({
          1: <img src="/assets/images/icons/money_bag.svg"/>,
          2: <img src="/assets/images/icons/bank.svg"/>,
          3: <img src="/assets/images/icons/card.svg"/>,
        });
        import('../themes/Clarity.scss');
        break;
      case 'turcode.local':
        import('../themes/Local.scss');
        break;
      default:
        isDefaultTheme = true;
        import('../themes/Default.scss');
        break;
    }
  }, []);

  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [getBankList] = useGetBankListMutation()
  const [createTransaction] = useCreateTransactionMutation()

  const steps = [t('account_deposit'), t('choose_a_bank'), t('make_a_transfer')];
  const ProgressStepper = ({ activeStep }) => {
    return (
      <div className="stepper-container">
        <Stepper activeStep={activeStep} alternativeLabel connector={<IconStepConnector />}>
          {steps.map((label, index) => (
            <Step key={index}>
              <StepLabel StepIconComponent={themeProperties.stepIcons}>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {activeStep < 2 && (
          <div className="current-step-text">{steps[activeStep]}</div>
        )}
      </div>
    );
  };
  const StripeProgressStepper = ({ activeStep }) => {
    return (
      <div className="stripe-stepper-container">
        <div className="stepper-stripes" step={activeStep.toString()}>
          {steps.map((_, index) => (
            <div
              key={index}
              data-step-number={index + 1}
              className={`step ${index === activeStep ? "active" : ""}`}
            ></div>
          ))}
        </div>
        {activeStep < 2 && (
          <div className="current-step-text">{steps[activeStep]}</div>
        )}
      </div>
    );
  };

  const auth = Cookies.get('auth', { domain: '.' + settings.base })
  const {
    id, referer, randomization, logo, title, payload, language, page, order
  } = useSelector((state) => ({
    id: state.root.auth.mid,
    referer: state.root.data.referer,
    logo: state.root.auth.logo,
    title: state.root.auth.title,
    payload: state.root.auth.payload,
    randomization: state.root.auth.randomization,
    key: state.root.auth.key,
    language: state.root.app.language,
    page: state.root.app.page,
    order: state.root.data.order
  }));

  const getPageContent = (step) => {
    switch (step) {
      case 0:
        return <MoneyForm {...{ isDefaultTheme }} />
      case 1:
        return <BankForm {...{ isDefaultTheme }} />
      case 2:
        return <TransactionForm {...{ isDefaultTheme }} />
      default:
        return <Box />
    }
  }

  const logout = async () => {
    dispatch(resetAuth())
  }

  React.useEffect(() => {
    const fetchData = async () => {
      if (payload) {
        try {
          const { bank: bankData, currency: currencyData, amount: amountData, order: orderData, token: tokenData, email: emailData, name: nameData, surname: surnameData, national: nationalData, referer: refererData, callback: callbackData } = JSON.parse(payload);

          if (order !== orderData) {
            dispatch(resetTransaction())
            dispatch(resetData())

            if (bankData && amountData && currencyData && orderData && refererData) {
              dispatch(setCurrency(currencyData));
              dispatch(setPrice(amountData));
              dispatch(setRefererURL(refererData));
              dispatch(setOrder(orderData));
              dispatch(setBank(bankData))

              if (tokenData) dispatch(setToken(tokenData));
              if (emailData) dispatch(setEmail(emailData));
              if (nameData) dispatch(setName(nameData));
              if (surnameData) dispatch(setSurname(surnameData));
              if (nationalData) dispatch(setNational(nationalData));
              if (callbackData) dispatch(setCallbackURL(callbackData));

              const response = await createTransaction({ id, order: orderData, token: tokenData, price: amountData, currency: currencyData, bank: bankData, email: emailData, name: nameData, surname: surnameData, national: nationalData, callback: callbackData, randomization }).unwrap()

              dispatch(setTransaction(response))

              dispatch(setPrice(response.price))
              dispatch(setError(false))
              dispatch(setPage(2))
            } else if (amountData && currencyData && orderData && refererData) {
              dispatch(setCurrency(currencyData));
              dispatch(setPrice(amountData));
              dispatch(setRefererURL(refererData));
              dispatch(setOrder(orderData));

              if (tokenData) dispatch(setToken(tokenData));
              if (emailData) dispatch(setEmail(emailData));
              if (nameData) dispatch(setName(nameData));
              if (surnameData) dispatch(setSurname(surnameData));
              if (nationalData) dispatch(setNational(nationalData));
              if (callbackData) dispatch(setCallbackURL(callbackData));

              const response = await getBankList({ currency: currencyData, price: amountData, }).unwrap()

              dispatch(setBanks(response));
              dispatch(setError(false))
              dispatch(setPage(1));
            } else if (orderData && refererData) {
              dispatch(setRefererURL(refererData));
              dispatch(setOrder(orderData));

              if (tokenData) dispatch(setToken(tokenData));
              if (emailData) dispatch(setEmail(emailData));
              if (nameData) dispatch(setName(nameData));
              if (surnameData) dispatch(setSurname(surnameData));
              if (nationalData) dispatch(setNational(nationalData));
              if (callbackData) dispatch(setCallbackURL(callbackData));

              dispatch(setError(false))
              dispatch(setPage(0));
            }
          }
        } catch (e) {
          dispatch(resetTransaction())
          dispatch(setError(true))
          dispatch(setPage(0))
        }
      }
    }

    fetchData();
  }, [payload, dispatch]);

  React.useEffect(() => {
    const ulanguage = new URLSearchParams(window.location.search).get('language');
    if (ulanguage && ulanguage.length === 2) {
      dispatch(setLanguage(ulanguage));
      const url = window.location.protocol + "//" + window.location.host + window.location.pathname;
      window.history.pushState({ path: url }, '', url);
    }
  }, [dispatch]);

  React.useEffect(() => {
    if (auth === undefined || !auth) {
      dispatch(resetTransaction())
      dispatch(resetData())
      dispatch(resetAuth())
      dispatch(setPage(0))

      window.location.href = settings.dashboard
    } else {
      (async () => {
        try {
          dispatch(setUser());
        } catch (e) {
          console.log(e);
        }
      })();
    }

    const storedLanguage = Cookies.get('language', { domain: '.' + settings.base });
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
    }

    if (i18n.isInitialized && i18n.language !== language) {
      i18n.changeLanguage(language);
      Cookies.set('language', language, {
        expires: 10,
        domain: '.' + settings.base,
        path: '/',
        secure: true,
        sameSite: 'lax',
      });
    }
  }, [id, auth, language, dispatch]);

  if ((auth === undefined || !auth) || (auth && !id)) {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Box sx={{ width: '100%' }}>
          <LinearProgress color="inherit" />
        </Box>
      </ThemeProvider>
    )
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <I18nextProvider i18n={i18n}>
        <Box sx={{ flexGrow: 1 }}>
          <AppBar position="static">
            <Toolbar>
              <Avatar alt="Logo" src={logo} sx={{ width: 30, height: 30, borderRadius: 0 }} />
              <Typography variant="h6" component="div" sx={{ ml: 1, flexGrow: 1 }} dangerouslySetInnerHTML={{ __html: title }}></Typography>
              <LanguageSwitcher {...{ isDefaultTheme }} />
              {!referer && <Box>
                <IconButton size="large" edge="end" aria-label="Dashboard" onClick={() => { window.location.href = settings.dashboard }} aria-haspopup="true" color="inherit" sx={{ ml: 1 }} >
                  {isDefaultTheme ? <AccountCircle /> : <DashboardIcon />}
                </IconButton>
              </Box>}
            </Toolbar>
          </AppBar>
        </Box>
        <Container component="main" maxWidth={false} sx={{ maxWidth: '480px', my: 4 }}>
          <Paper elevation={3} className='main-frame-container' sx={{ display: 'flex', flexDirection: 'column', minHeight: 700, borderRadius: 6, my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
            <StripeProgressStepper activeStep={!auth && !id ? -1 : page} />
            <ProgressStepper activeStep={!auth && !id ? -1 : page} />
            {isDefaultTheme && (
              <Stepper alternativeLabel activeStep={!auth && !id ? -1 : page} connector={<IconStepConnector />} sx={{ pt: 3, pb: 5 }}>
                <Step key={0}>
                  <StepLabel StepIconComponent={StepIcons}>{t('enter_amount')}</StepLabel>
                </Step>

                <Step key={1}>
                  <StepLabel StepIconComponent={StepIcons}>{t('choose_a_bank')}</StepLabel>
                </Step>

                <Step key={2}>
                  <StepLabel StepIconComponent={StepIcons}>{t('make_a_transfer')}</StepLabel>
                </Step>
              </Stepper>
            )}
            <React.Fragment>
              {auth && id && getPageContent(page)}
            </React.Fragment>
          </Paper>
        </Container>
      </I18nextProvider>
    </ThemeProvider>
  )
}

export default App
