import React from 'react'
import { styled } from '@mui/material/styles'
import { Check } from '@mui/icons-material'

export default function StepIconsSetup(icons) {
    return (props) => {
        const { active, completed, className } = props

        const IconStepperBase = styled('div')(({ theme, ownerState }) => ({
            backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
            zIndex: 1,
            color: '#fff',
            width: 55,
            height: 55,
            display: 'flex',
            borderRadius: '50%',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: 20,
        }));

        if (icons) {
            return (
                <IconStepperBase ownerState={{ completed, active }} className={className}>
                    {icons[String(props.icon)]}
                </IconStepperBase>
            )
        } else {
            return (
                <IconStepperBase ownerState={{ completed, active }} className={className}>
                    {completed ? (
                        <Check className="complete-icon" />
                    ) : (
                        props.icon
                    )}
                </IconStepperBase>
            )
        }
    }
}
