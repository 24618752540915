import React from 'react';
import settings from 'settings';

export const Timer = ({ datetime }) => {
    const [timeRemaining, setTimeRemaining] = React.useState('06:00');

    React.useEffect(() => {
        const targetTime = new Date(`${datetime}Z`).getTime();
        const endTime = targetTime + settings.transactionDuration * 1000;

        const updateTimer = () => {
            const now = new Date().getTime();
            const distance = endTime - now;

            if (distance <= 0) {
                setTimeRemaining('00:00');
                return false;
            }

            const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((distance % (1000 * 60)) / 1000);
            setTimeRemaining(`${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`);

            return true;
        };

        if (!updateTimer()) return;

        const intervalId = setInterval(() => {
            if (!updateTimer()) {
                clearInterval(intervalId);
            }
        }, 1000);

        return () => clearInterval(intervalId);
    }, [datetime]);

    return <div>{timeRemaining}</div>;
};
