import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useCreateTransactionMutation } from '../app/API'

import { setTransaction } from '../slices/TransactionSlice'
import { setBank, setPrice, setError, resetData } from '../slices/DataSlice'
import { setPage } from '../slices/AppSlice'

import { Box, Button, } from '@mui/material'
import { ImageButton, ImageSrc, ImageBackdrop } from '../components/StyledComponents'

export default function PaymentForm() {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const {
    id, referer, randomization, price, currency, email, name, surname, national, callback, banks, order, token
  } = useSelector(state => ({
    id: state.root.auth.mid,
    referer: state.root.data.referer,
    randomization: state.root.auth.randomization,
    price: state.root.data.price,
    currency: state.root.data.currency,
    email: state.root.data.email,
    name: state.root.data.name,
    surname: state.root.data.surname,
    national: state.root.data.national,
    callback: state.root.data.callback,
    banks: state.root.app.banks,
    order: state.root.data.order,
    token: state.root.data.token
  }));

  const [createTransaction] = useCreateTransactionMutation()

  const handleBankSelect = async (bank) => {
    dispatch(setBank(bank))
    await submit(bank)
  }

  const revert = async () => {
    dispatch(resetData())
    dispatch(setPage(0))
  }

  const submit = async (bank) => {
    if (!bank) {
      alert(t('error_no_bank_selected'))
      return
    }

    const data = { order, token, randomization, price, bank: bank.id, currency, id, email, name, surname, national, callback }

    try {
      const response = await createTransaction(data).unwrap()
      dispatch(setTransaction(response))
      dispatch(setError(false))
      dispatch(setPrice(response.price))
      dispatch(setPage(2))
    } catch (e) {
      dispatch(setError(true))

      if (banks.length === 1) {
        dispatch(setPage(0))
      }
    }
  }

  React.useEffect(() => {
    if (banks.length === 1) {
      handleBankSelect(banks[0]);
    }
  }, [banks]);

  if (banks.length === 1) {
    return (
      <React.Fragment />
    )
  }

  return (
    <div className='bank-form-container'>
      {banks.map((bank) => (
        <ImageButton className='bank-button' focusRipple onClick={() => handleBankSelect(bank)} key={bank.id} sx={{ mb: 2 }}  >
          <ImageSrc sx={{ backgroundImage: `url(${bank.logo_svg})` }} />
          <ImageBackdrop className="MuiImageBackdrop-root" />
        </ImageButton>
      ))}

      {
        !referer && <Box className='action-buttons'>
          <Button color="error" variant="contained" onClick={revert} sx={{ mt: 3, ml: 1, minWidth: '100px', }}>
            {t('back')}
          </Button>
        </Box>
      }
    </div>
  )
}